// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useEffect, useRef } from 'react'
import gsap from 'gsap/dist/gsap'

interface CounterProps {
  start: number
  end: number
  duration: number
  step?: number
  className?: string
}
export default function Counter(props: CounterProps) {
  const target = useRef(null)
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0]
      if (entry!.isIntersecting) {
        gsap.to(target.current, {
          innerText: props.end,
          snap: { innerText: props.step || 1 },
          duration: props.duration || 1
        })
        observer.unobserve(target.current)
      }
    })
    observer.observe(target.current!)
    return () => {
      if (target.current)
        observer.unobserve(target.current)
    }
  }, [])

  return (
    <span ref={target} className={`${props.className} will-change-contents`}>
      {props.start || 0}
    </span>
  )
}
